body{
    font-family: 'Roboto', sans-serif;
   }
   html, body {
    min-height: 100%;
}


header {
    background-color: #2f3338;
    padding: 10px 15px;
    margin-bottom:5%;
}

.loginText{
    color:#2f3338;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

/* Header Search Bar */
.header-search input[type="text"] {
    border: none;
}
.header-search .input-group-addon button {
    color: #fff;
    background: #ffc107;
    padding: 10px 15px;
    border: none;
    font-size: 13px;
}

.logo-text{
    color:#ffc107;
    font-size: 1.7rem;
}
.select-style {
    width: auto;
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
}
.select-style select {
    width: 100%;
    background: #efefef;
    color: #000;
    height: 38px;
    padding: 0px 9px;
    border: none;
    outline: none;
}
.select-style select option.topshow {
    padding: 10px 0;
    position: relative;
    top: 1px;
}
/* End Header Search Bar */


/* Header cart CSS */
header .cart a {
    color: #fff;
    text-decoration: none;
}
header .cart {
    position: relative;
    left: -15px;
}
header .cart a span.number {
    position: relative;
    left: 38px;
    top: -4px;
    color: #ef2d2c;
}
header .cart a span.number {
    position: relative;
    left: 38px;
    top: -4px;
    color: #ffffff;
}
header .cart a {
    color: #fff;
    text-decoration: none;
}
/* End Header cart CSS */


/* choose store checkbox CSS */
header [type="radio"]:checked + label,
header [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 5px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #fff;
    font-family: "Conv_VisueltPro-Regular";
    font-size: 14px;
}


/* Responsive CSS */
@media (max-width: 991px) {
    header .cart a img {
        width: 30px;
        margin-left: 10px;
    }
    header .cart a span.number {
        left: 40px;
        top: -7px;
        font-size: 14px;
    }
    header {
        padding: 10px 0px;
    }
    .cart-login {
        position: absolute;
        right: 70px;
    }
    header .navbar-brand {
        max-width: 150px;
    }
}

.user-menu a{color:#000 !important}

.bgColor{background-color: #fff;}