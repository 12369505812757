.features li{
    font-size: 1.3 rem;
    padding: 1%;
    color:#000;
    font-weight: bold;
}
.features li:hover {
    cursor: pointer;
    background-color: #ebeeef;
    color:rgb(71, 106, 182);
}