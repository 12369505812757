@import url(https://fonts.googleapis.com/css?family=Montserrat);

* {
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
  background: #ffeadb;
}

body {
  font-family: montserrat, arial, verdana;
}

#multistepsform {
  width: 100%;
  margin: 0px auto;
  text-align: center;
  position: relative;
  content-visibility: auto;
 
 
  #progressbar {
    margin-bottom: 0px;
    overflow: hidden;
    counter-reset: step;
    margin-left: 12%;
  
    li {
      list-style-type: none;
      color: #679b9b;
      text-transform: uppercase;
      font-size: 9px;
      width: 12.33%;
      float: left;
      position: relative;
      &:before {
        content: counter(step);
        counter-increment: step;
        width: 20px;
        line-height: 20px;
        display: block;
        font-size: 10px;
        color: #fff;
        background: #679b9b;
        border-radius: 3px;
        margin: 0 auto 5px auto;
      }
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: #679b9b;
        position: absolute;
        left: -50%;
        top: 9px;
        z-index: -1;
      }
      &:first-child:after {
        content: none;
      }
    }
    li.active {
      color: #ff9a76;
      &:before,
      &:after {
        background: #ff9a76;
        color: white;
      }
    }
  }
}
