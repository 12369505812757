.home-testimonial {
    background-color:#dac067;
    height: 380px
}

.home-testimonial-bottom {
    /* background-color: #f8f8f8; */
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 20px;
    margin-bottom: 0px;
    position: relative;
    height: 130px;
    top: 190px
}

.home-testimonial h3 {
    color: var(--orange);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase
}

.home-testimonial h2 {
    color: white;
    font-size: 28px;
    font-weight: 700
}

.testimonial-inner {
    position: relative;
    top: -174px
}

.testimonial-pos {
    position: relative;
    top: 24px
}

.testimonial-inner .tour-desc {
    border-radius: 5px;
    padding: 40px
}

.color-grey-3 {
    font-family: "Montserrat", Sans-serif;
    font-size: 14px;
    color: #6c83a2
}

.testimonial-inner img.tm-people {
    width: 60px;
    height: 60px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    max-width: none
}

.link-name {
    font-family: "Montserrat", Sans-serif;
    font-size: 14px;
    color: #6c83a2
}

.link-position {
    font-family: "Montserrat", Sans-serif;
    font-size: 12px;
    color: #6c83a2
}

.contentp{
font-size: 1.3rem;
}

.service-inner{
    width:100%;
    min-height:300px;
    box-shadow: 5px 10px 18px #888888;

}

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 300px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }
  
  .flip-card:focus {
      outline: 0;
  }
  
  .flip-card:hover .flip-card-inner,
  .flip-card:focus .flip-card-inner{
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .flip-card-front {
    background: linear-gradient(to left, #eee, #fff);
    color: #000;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

  }
  .flip-card-front h3{color:#333;}
  
  .flip-card-back {
    background: linear-gradient(to right, #2f3338, #2f3338);
    color: #fff;
    transform: rotateY(180deg);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  h3 {
    font-size: 20px;
    font-family: Verdana, sans-serif;
    font-weight: bold;
    color: #fff;
  }

  .service-p{
    padding:1% 3%;
    text-align: justify;
    font-size: .8rem;
  }
  