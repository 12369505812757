.content {
    /* position: fixed; */
    left: 0;
    right: 0;
    z-index: 9999;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 1.5rem;
    padding:8%;
    color:#eee;
  }
  /* .tales {
    width: 100%;
  } */
   .myslide .carousel-item{
    width:100%;
    max-height: 300px !important;
  }
  /* Smaller than standard 960 (devices and browsers) */
@media only screen and (max-width: 959px) {}

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 768px) and (max-width: 959px) {}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {}

/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 480px) and (max-width: 767px) {}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width: 479px) {}

.bottom-content h2{
  font-size: 1rem;
  font-weight: bold;
  padding:5% 5%;
}