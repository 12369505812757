header nav .navbar-nav .nav-link {
    /* color: #efefef !important; */
    font-size: 1rem;
    font-weight: 300;
    padding: 2px 16px !important;
    font-family: 'Poppins', sans-serif;
    /* background-color:#ebeeef; */
}
header .navbar-brand {
    max-width: 200px;
}