@import url("https://fonts.googleapis.com/css2?family=Poppins:ital@1&display=swap");

/* body {
	background: rgb(15, 6, 24);
	background: linear-gradient(
		322deg,
		rgba(15, 6, 24, 1) 20%,
		rgba(46, 19, 78, 1) 100%
	);
} */

.loader-title {
	display: flex;
	justify-content: center;
	color: #eeecf0;
	font-family: Poppins;
	user-select: none;
	text-shadow: 3px 3px black;
}

.title-container {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    width:100%;
    height:100%;
}

.spinner {
	display: flex;
	justify-content: center;
}

.svg {
	width: 50%;
	max-width: 10rem;
	animation: rotate 3.6s linear infinite;
}

circle {
	fill: none;
	stroke: #eeecf0;
	stroke-width: 8px;
	stroke-dasharray: 300;
	animation: outline 2s cubic-bezier(0.77, 0, 0.18, 1) infinite;
}

@keyframes outline {
	0% {
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dashoffset: 300;
	}
	100% {
		stroke-dashoffset: 600;
	}
}

@keyframes rotate {
	from {
		transform: rotate(0turn);
	}
	to {
		transform: rotate(-1turn);
	}
}
