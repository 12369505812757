* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    min-height: 100vh;
    background-color: #fff;
  }
  i, a{color:#fff}
  .headIcon{font-size: 1.5rem;
    text-align: center;
    padding-left: 15%;}
  li span{color:#fff;}
  .notify-text{
    margin-top: -39px;
    margin-left: 44%;
    color: #140103;
    font-size: 25px;
    background-color: #fff;
    padding: 0%;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    font-size: 17px;
   border: 1px solid rgb(0, 140, 255); 
    text-align: center;
  }
  .table th{
      background-color:#fcc237;
      color:#000;
      padding:.5rem;
      text-align: justify;
  }
  .table td{
    
    padding:.5rem;
    text-align: justify;
}

.action-list {
    padding:0% 0%;
    margin:0% 0%;

}
  .action-list li{list-style: none;
    padding:0% 1%;

}

  .nav-link-inner {
    display: block;
    padding: 0.5rem 0rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
  
  .side-navbar {
    /* width: 250px; */
    height: 100%;
    position: fixed;
    margin-left: -12px;
    background-color: #100901;
    transition: 0.5s;
  }
  
  .nav-link:active,
  .nav-link:focus,
  .nav-link:hover {
    background-color: #ffffff26;
  }
  
  .my-container {
    transition: 0.4s;
  }
  
  .active-nav {
    margin-left: 0;
  }
  
  /* for main section */
  .active-cont {
    margin-left: 250px;
  }
  
  #menu-btn {
    background-color: #100901;
    color: #fff;
    margin-left: -62px;
  }
  
  .my-container input {
    border-radius: 2rem;
    padding: 2px 20px;
  }

  .totalBox li{display: inline-block; list-style: none; margin: 2%; width: 25%;}
  .innerBox{background-color: #fcc237; width: 100%;  border-radius:10px;}
  