h3.title{
    font-size:.9rem;
    padding:1rem;
    /* color:#2f3338; */
    color:#1e404e;
}
a{text-decoration: none;}
.service-desc li{
    list-style: none;
    background-color: #fff;
    margin-top: 3px;
    padding: 1%;
    font-size: 0.8rem;
    border: 1px solid #eee;
    padding: 3px 11px;
    color: #1e404e;
    font-weight: bold;
}
.service-desc li:hover{
    cursor: pointer;
}
.bottom-list li{
    list-style:none;
    font-size: 1.7rem;
    display: inline-flex;
    padding: 0% 23% 0% 23%;
}

.review-span{
    font-size: 14px;
    padding: 1%;
    color: #000000;
}

img, svg {
    vertical-align: middle;
    color: #ffc107;
    margin-right: 5%;
}
.btn-listing{
    color:#000;
    opacity: 1;
    border:1px solid #ffc107;
}
.btn-dk {
    /* background-color: #ffc107; */
    color:#000;
    opacity: 1;
    border:1px solid #ffc107;
}
.btn-dk:hover, .btn-listing:hover {
    background-color: #ffc107;
    color:#000;
    opacity: 1;
    border:1px solid #ffc107;
}
.bg-dk {
    background-color: #1e404e;
    color: #fff;
    font-weight: bold;
}
.btn-link{color: #000000; text-decoration: none; text-align: center; display: block;}
.single-img img{margin-left:-12px !important; max-width: 100%;}

.likeShare li{
    list-style:none;
    display: inline;
   font-size:1.5rem;
   margin-top: 1%;
}
.sharebox{
    font-size:1.5rem;
    color:#e3e3e3
}

.sharebox:hover{
 color: #ffc107;
}