footer{
    margin-block-start:10%;
    background-color:#ebeeef;
    bottom: 0;
    left: 0;
    position: relative;
    width: 100%;
    margin-top: 20%;
}
footer li, a{list-style: none; 
color:rgba(0,47,52,.64);
cursor: pointer;
font-size: .8rem;
}

footer h3{font-size:1rem; color:#333; font-weight: 300;}
.footer-lower{width:100%; background-color: #333; color: #fff; padding:1%; display:flex;
}
.footer-lower a{color:#fff; padding:0px 10px;}

.right{margin-left:auto;}
.right span{font-size: .8rem;}